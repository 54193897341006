













































import DataContainer from "../common/DataContainer";
import defaultComponent from "../utils/defaultComponent";
import merge from "../utils/merge";

export default defaultComponent.apply({
  props: ["value", "label"],
  data() {
    return {
      formOptionsTemplate: {},
      items: [],
      itemFormOptions: [],
      itemFormDatas: [],
      itemFormContainers: [],
      itemValids: [],
      expandedIndexes: [],
      removedItems: {},
    };
  },
  watch: {
    value(value) {
      this.setValue(value);
    },
    calculatedValid(value) {
      this.$emit("validated", value);
    },
  },
  computed: {
    calculatedItems() {
      const items = [];
      for (const i in this.items) {
        if (this.removedItems[i]) {
          continue;
        }
        items.push(this.items[i]);
      }
      return items;
    },
    calculatedValid() {
      for (const i in this.items) {
        if (this.removedItems[i]) {
          continue;
        }
        if (!this.itemValids[i].value) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    initialize() {
      this.options.content.addItemBtn.on.xClick = ({ self }) => {
        self.context().addItem(undefined, true);
      };

      this.formOptionsTemplate = merge.clone(this.options.content.template.formOptions, {
        content: {
          sections: {
            default: {
              fields: this.options.content.itemProperties,
            },
          },
        },
      });

      this.setValue(this.value);
    },
    setValue(value) {
      if (JSON.stringify(value) === JSON.stringify(this.calculatedItems)) {
        return;
      }

      this.items = [];
      this.itemFormOptions = [];
      this.itemFormDatas = [];
      this.itemFormContainers = [];
      this.itemValids = [];

      if (value) {
        for (const item of value) {
          this.addItem(item, !this.options.content.defaultExpandDisabled);
        }
      }
    },
    addItem(item?, expand = false) {
      if (!item) {
        let template = this.options.content.itemTemplate;
        if (template === undefined) {
          template = {};
        }
        item = merge.clone(template);
      }

      const formOptions = merge.clone(this.formOptionsTemplate);
      const formData = new DataContainer(item);
      const formContainer = new DataContainer();
      const formValid = new DataContainer(true);

      this.items.push(item);
      this.itemFormOptions.push(formOptions);
      this.itemFormDatas.push(formData);
      this.itemFormContainers.push(formContainer);
      this.itemValids.push(formValid);

      if (expand) {
        const index = this.items.length - 1;
        this.$nextTick(() => {
          this.expandItem(index);
        });
      }
    },
    removeItem(i) {
      this.$set(this.removedItems, i, true);
      this.$emit("input", this.calculatedItems);
      this.$forceUpdate();
    },
    expandItem(index) {
      if (this.expandedIndexes.includes(index)) {
        return;
      }
      this.expandedIndexes.push(index);
    },
    onItemUpdated(i, item) {
      this.$set(this.items, i, item);
      this.$emit("input", this.calculatedItems);
    },
    realIndex(index) {
      let realIndex = 0;
      for (const i in this.items) {
        if (this.removedItems[i]) {
          continue;
        }
        if (+i === +index) {
          return realIndex;
        }
        realIndex++;
      }
      return realIndex;
    },
  },
  extraOptions: {
    defaultClass: "arrayInput",
  },
});
